import styled from "styled-components";
import {BsDot} from "react-icons/bs";

interface TimedChoiceMultiCtrlProps{
    className?:string;
    TabChoice:Choice[];
    ChoicesCurrent:Choice[];
    setChoice:(choices:Choice[])=>void;
    libelle:string;
}

const TimedChoiceMultiCtrl = (props:TimedChoiceMultiCtrlProps)=>{
    const handleClickAll = ()=>{
        props.setChoice(props.TabChoice);
    }
    const handleClickNone = ()=>{
        props.setChoice([]);
    }
    const handleClickOne = (item:Choice)=>{
        const Exist = props.ChoicesCurrent.map(i=>i.id).indexOf(item.id)!==-1
        if(Exist){
            props.setChoice([...props.ChoicesCurrent.filter(i=>i.id !== item.id)])
        } else {
            props.setChoice([...props.ChoicesCurrent, item])
        }
    }
    return (
        <div className={`choice_multi ${props.className}`}>
            <div className="head_choice_multi">{props.libelle}</div>
            <div className="all_un">
                <div className="one_select_unselect" onClick={handleClickAll}>Tous</div>
                <BsDot/>
                <div className="one_select_unselect" onClick={handleClickNone}>Aucun</div>
            </div>
            <div className="wrap_choices">
                {props.TabChoice.map((item:Choice)=>(
                    <div onClick={()=>handleClickOne(item)} key={`on_C_M${item.id}`} className={`one_choice_multi ${props.ChoicesCurrent.map(i=>i.id).indexOf(item.id)!==-1 ? 'selected' : ''}`} >{item.libelle}</div>
                ))}
            </div>
        </div>
    )
}

export const TimedChoiceMulti = styled(TimedChoiceMultiCtrl)`
    .head_choice_multi{
      padding: 0.25rem;
      text-align: center;
      font-weight: bold;
    }
    .all_un{
      display: flex;
      justify-content: flex-start;
      gap: 3px;
      color: ${props => props.theme.PrimaryExtraLight};
      padding: 0.25rem;
      .one_select_unselect{
        flex-grow: 1;
        text-align: center;
        &:hover{
          cursor: pointer;
          color: ${props => props.theme.Primary};
        }
      }
    }
  .wrap_choices{
    .one_choice_multi{
      padding: 0.25rem;
      border-radius: 4px;
      margin-bottom: 2px;
      &.selected{
        background: ${props=>props.theme.PrimaryExtraLight};
      }
      &:hover{
        cursor: pointer;
        font-weight: bold;
      }
      &:not(.selected):hover{
        
      }
    }
  }
`