import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {
    AddEvts,
    addUserSignature,
    cancelCloturePeriodeAbs,
    CancelSub,
    changeLogo,
    cloturePeriodeAbs,
    createUser,
    duplicateSTS,
    forgot_pwd,
    getAllUser,
    getAllUserAdmin,
    getAllUserD,
    getAllUserFull,
    getAllUserSeq, getAllUserSub,
    getContrats,
    getInfosOneUser,
    getInfosPlanning,
    getInfosUser,
    getInfosUserMob, getIsOuvrableUser,
    getLieuxFiltered,
    getPlageSemCurr,
    getUserByFonctionFAdm,
    getUserByFonctions,
    getUserByMultiID,
    getUserByUserName,
    getUserReplace,
    getUsersByCentre, getUsersByCentreBorn,
    postWMSub,
    saveConfigPl,
    saveConfigPlCGI,
    saveConfigPlCGL,
    saveDashUser,
    saveOptin,
    sendIdentity, setIsOuvrableUser,
    trashUserSignature,
    updateCentreUC,
    updateInfosPerso,
    updateInfosPro,
    updateJoursTrav
} from "./user.services";
import {toast} from "react-toastify";
import {trashDocument} from "../userDocument/userDocument.services";

export const useGetUser=():UseQueryResult<UserSimple, Error>=>{
    return useQuery<UserSimple, Error>(["user_connected"], getInfosUser, {
        staleTime:60000,
        refetchOnWindowFocus:false,
    })
}

export const useModifyDashUser = ()=>{
    const queryClient = useQueryClient();
    return useMutation((datas:SaveDashUserFD)=>{
        return saveDashUser(datas);
    }, {
        onSuccess:()=>{
            queryClient.invalidateQueries(["user_connected"])
        }

    })
}

export const useGetUserParticularOuvrable = (id:number):UseQueryResult<ParticularOuvrable, Error>=>{
    return useQuery(["user_p_o", id], ()=>getIsOuvrableUser(id), {
        enabled:id!==0
    })
}

export const useSaveIsOuvrableUser = (id:number)=>{
    const queryClient = useQueryClient();
    return useMutation(
        (isOuvrable:number)=>{
            return setIsOuvrableUser(id, isOuvrable);
        }, {
            onSuccess:(newOuvrable:ParticularOuvrable)=>{
                queryClient.setQueryData(["user_p_o", id], newOuvrable)
            }
        }
    )
}

export const useGetUserMob=():UseQueryResult<MeSimply, Error>=>{
    return useQuery<MeSimply, Error>(["user_connected_mob"], getInfosUserMob, {
        staleTime:100000,
        refetchOnWindowFocus:false,
    })
}

export const useUpdateCentreUC = ()=>{
    const queryClient = useQueryClient();
    return useMutation((datas: {idUser:number, id:number })=>{
            return updateCentreUC(datas.idUser, datas.id)
        },
        {
            onSuccess:(newData)=>{
                queryClient.invalidateQueries(["users"]);
                queryClient.invalidateQueries(["user_connected"]);
                queryClient.setQueryData(["user", newData.id], newData);
                toast.success("profil modifié !", {});
            },
            onError: (error: any) => {
                toast.error("Erreur : " + error.message, {});
            },
        }

    )
}

export const useReplicateSTS = ()=>{
    const queryClient = useQueryClient();
    return useMutation((datas:{idUserFrom:number, idUserReplicate:number})=>{
        return duplicateSTS(datas.idUserFrom, datas.idUserReplicate)
    }, {
            onSuccess:(newDataUser:User)=>{
                queryClient.invalidateQueries(["centreSemaineType", newDataUser.id])
                queryClient.invalidateQueries(["user", newDataUser.id])
                toast.success('Les semaines types ont été dupliquées')
            },
            onError:()=>{
                toast.error("Une erreur est survenue")
            }
        }
    )
}



export const useSaveOptIn = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (id:number)=>{
            return saveOptin(id);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["user_connected"])
            }
        }
    )
}

export const useGetUserByCentre=(idCentre:number):UseQueryResult<User[], Error>=>{
    return useQuery<User[], Error>(["users_centre", idCentre], ({signal})=>getUsersByCentre(idCentre, signal), {
        staleTime:60000,
        refetchOnWindowFocus:true,
        enabled:idCentre!==0
    })
}

export const useAddUser = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:userNewFormData)=>{
            return createUser(datas);
        }, {
            onSuccess:(newData:User)=>{
                queryClient.invalidateQueries(["users"]);
                queryClient.setQueryData(["user", newData.id], newData);
            }
        }
    )
}

export const useSaveConfigPl=()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:UserConfigFormData)=>{
            return saveConfigPl(datas);
        },{
            onSuccess:(newUser:User)=>{
                queryClient.setQueryData(["user_connected"], newUser);
                toast.success('Configuration enregistrée');
            },
            onError:(error:any)=>{
                toast.error("Erreur : " + error.message, {});
            }
        }
    )
}

export const useSaveConfigPlCGL=()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:UserConfigFormData)=>{
            return saveConfigPlCGL(datas);
        },{
            onSuccess:(newUser:User)=>{
                queryClient.setQueryData(["user_connected"], newUser);
                toast.success('Configuration enregistrée');
            },
            onError:(error:any)=>{
                toast.error("Erreur : " + error.message, {});
            }
        }
    )
}

export const useSaveConfigPlCGI=()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:UserConfigFormData)=>{
            return saveConfigPlCGI(datas);
        },{
            onSuccess:(newUser:User)=>{
                queryClient.setQueryData(["user_connected"], newUser);
                toast.success('Configuration enregistrée');
            },
            onError:(error:any)=>{
                toast.error("Erreur : " + error.message, {});
            }
        }
    )
}

export const useGetAllUser=(idCentre:number|null=null):UseQueryResult<User[], Error>=>{
    return useQuery<User[], Error>(["users", idCentre], ()=>getAllUser(idCentre), {
        staleTime:60000,
        refetchOnWindowFocus:false,
    })
}

export const useGetAllAdmin = ():UseQueryResult<User[], Error>=>{
    return useQuery<User[], Error>(["users_admin"], ()=>getAllUserAdmin(), {
        staleTime:60000,
        refetchOnWindowFocus:false,
    })
}

export const useGetAllUserSeq=(idCentre:number|null=null):UseQueryResult<User[], Error>=>{
    return useQuery<User[], Error>(["users_seq", idCentre], ()=>getAllUserSeq(idCentre), {
        staleTime:60000,
        refetchOnWindowFocus:false,
        enabled:idCentre!==0
    })
}

export const useGetAllUserD=(idCentre:number|null=null):UseQueryResult<User[], Error>=>{
    return useQuery<User[], Error>(["users_d", idCentre], ()=>getAllUserD(idCentre), {
        staleTime:60000,
        refetchOnWindowFocus:false,
    })
}

export const useGetAllUserSub=():UseQueryResult<User[], Error>=>{
    return useQuery<User[], Error>(["users_sub"], getAllUserSub, {
        staleTime:60000,
        refetchOnWindowFocus:false,
    })
}

export const useGetAllUserFull=():UseQueryResult<User[], Error>=>{
    return useQuery<User[], Error>(["users_full"], getAllUserFull, {
        staleTime:60000,
        refetchOnWindowFocus:false,
    })
}

export const useGetOneUser = (id:number):UseQueryResult<User, Error>=>{
    return useQuery<User, Error>(["user", id], ()=>getInfosOneUser(id), {
        staleTime:60000,
        enabled:id!==0,
        refetchOnWindowFocus:false,
    })
}
export const useGetReal = ():UseQueryResult<User, Error>=>{
    return useQuery<User, Error>(["user_real"], getUserByUserName, {
        staleTime:60000,
    })
}

export const useSaveWMSub = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:WMsubFormData)=>{
            return postWMSub(datas);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["user_real"]);
                queryClient.invalidateQueries(["user"]);
            },
            onError:()=>{
                toast.error("Une erreur est survenue");
            }
        }
    )
}

export const useCancelWMSub = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        ()=>{
            return CancelSub();
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["user_real"]);
                queryClient.invalidateQueries(["user"]);
            },
            onError:()=>{
                toast.error("Une erreur est survenue");
            }
        }
    )
}

export const useGetUsersByFonction = (list:string[], idCentre:number|null=null):UseQueryResult<User[], Error>=>{
    return useQuery<User[], Error>(["users_fonctions", list.join('#'), idCentre], ()=>getUserByFonctions(list, idCentre), {
        staleTime:60000,
        refetchOnWindowFocus:false,
    })
}

export const useGetUsersByFonctionFAdm = (idFx:number, idFxAdm:number, idCentre:number|null=null, dismiss=false):UseQueryResult<User[], Error>=>{
    return useQuery<User[], Error>(["users_fx_fxadm", idFx, idFxAdm, idCentre, dismiss], ()=>getUserByFonctionFAdm(idFx, idFxAdm, idCentre, dismiss), {
        staleTime:60000,
        refetchOnWindowFocus:false,
    })
}

export const useGetUsersByIds = (list:string[]):UseQueryResult<User[], Error>=>{
    return useQuery<User[], Error>(["users_ids", list.join('#')], ()=>getUserByMultiID(list), {
        staleTime:60000,
        refetchOnWindowFocus:false,
    })
}


export const useAddEvt = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (data:UserEvtAddFormData)=>{
            return AddEvts(data);
        },
        {
            onSuccess:()=>{
                queryClient.invalidateQueries(["events"]);
                queryClient.invalidateQueries(["events_dates"]);
                queryClient.invalidateQueries(["documents"]);
                toast.success("Evt Ajouté !", {});
            }
        }
    )
}


export const useChangeLogo = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (data:UserAvatarFormData) => {
            return changeLogo(data.File, data.id);
        },
        {
            onSuccess: (newData) => {
                queryClient.invalidateQueries(["users"]);
                queryClient.invalidateQueries(["user_connected"]);
                queryClient.setQueryData(["user", newData.id], newData);
                toast.success("Image de profil modifiée !", {});
            },
            onError: (error: any) => {
                toast.error("Erreur : " + error.message, {});
            },
        }
    );
};

export const useTrashSignature = ()=>{
    return useMutation(
        (id:number)=>trashUserSignature(id)
    )
}
export const useAddSign = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (data:PostNewSignature) => {
            return addUserSignature(data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["users"]);
                queryClient.invalidateQueries(["user"]);
                queryClient.invalidateQueries(["user_connected"]);
                toast.success("signature modifiée !", {});
            },
            onError: (error: any) => {
                toast.error("Erreur : " + error.message, {});
            },
        }
    );
};

export const useUpdateInfosPerso = ()=>{
    const queryClient = useQueryClient();
    return useMutation((datas:UserInfoPersoFormData)=>{
        return updateInfosPerso(datas)
    },
        {
            onSuccess:(newData)=>{
                queryClient.invalidateQueries(["users"]);
                queryClient.invalidateQueries(["user_connected"]);
                queryClient.invalidateQueries(["creneaux_fp"]);
                queryClient.invalidateQueries(["creneaux_fp"]);
                queryClient.invalidateQueries(["plages"]);
                queryClient.invalidateQueries(["plages_week"]);
                queryClient.invalidateQueries(["stat_pointage"]);
                queryClient.setQueryData(["user", newData.id], newData);
                toast.success("profil modifié !", {});
            },
            onError: (error: any) => {
                toast.error("Erreur : " + error.message, {});
            },
        }

    )
}
export const useUpdateInfosPro = ()=>{
    const queryClient = useQueryClient();
    return useMutation((datas:UserInfosProfessionnellesFormData)=>{
            return updateInfosPro(datas)
        },
        {
            onSuccess:(newData)=>{
                queryClient.invalidateQueries(["users"]);
                queryClient.invalidateQueries(["user_connected"]);
                queryClient.setQueryData(["user", newData.id], newData);
                toast.success("profil modifié !", {});
            },
            onError: () => {
                toast.error("Une erreur est survenue, merci de retenter plus tard");
            },
        }

    )
}
export const useUpdateJoursTrav = ()=>{
    const queryClient = useQueryClient();
    return useMutation((datas: {idUser:number, jt:JoursTravail })=>{
            return updateJoursTrav(datas.idUser, datas.jt)
        },
        {
            onSuccess:(newData)=>{
                queryClient.invalidateQueries(["users"]);
                queryClient.invalidateQueries(["user_connected"]);
                queryClient.setQueryData(["user", newData.id], newData);
                toast.success("profil modifié !", {});
            },
            onError: (error: any) => {
                toast.error("Erreur : " + error.message, {});
            },
        }

    )
}

export const useSendIdentity = ()=>{
    const queryClient = useQueryClient();
    return useMutation((id:number)=>{
        return sendIdentity(id);
    },
        {
            onSuccess:(newData)=>{
                queryClient.invalidateQueries(["users"]);
                queryClient.invalidateQueries(["user_connected"]);
                queryClient.setQueryData(["user", newData.id], newData);
                toast.success("Identifiants envoyés !", {});
            },
            onError: (error: any) => {
                toast.error("Une erreur est survenue, merci de retenter plus tard");
            },
        }
    )
}

export const useCloturePeriodeAbs = ()=>{
    const queryClient = useQueryClient();
    return useMutation((data:{dispo:number, idperiode:number, iduser:number})=>{
            return cloturePeriodeAbs(data.dispo, data.idperiode, data.iduser);
        },
        {
            onSuccess:()=>{
                queryClient.invalidateQueries(["compteur_abs_date"]);
                queryClient.invalidateQueries(["compteur_abs"]);
                toast.success("Periode cloturée !", {});
            },
            onError: (error: any) => {
                toast.error("Une erreur est survenue, merci de retenter plus tard");
            },
        }
    )
}

export const useCancelCloturePeriodeAbs = ()=>{
    const queryClient = useQueryClient();
    return useMutation((data:{idperiode:number, iduser:number})=>{
            return cancelCloturePeriodeAbs(data.idperiode, data.iduser);
        },
        {
            onSuccess:()=>{
                queryClient.invalidateQueries(["compteur_abs_date"]);
                queryClient.invalidateQueries(["compteur_abs"]);
                toast.success("Cloture annulée !", {});
            },
            onError: (error: any) => {
                toast.error("Une erreur est survenue, merci de retenter plus tard");
            },
        }
    )
}

export const useSendIdentityWM = ()=>{
    const queryClient = useQueryClient();
    return useMutation((id:number)=>{
            return sendIdentity(id, true);
        },
        {
            onSuccess:(newData)=>{
                queryClient.invalidateQueries(["users"]);
                queryClient.invalidateQueries(["user_connected"]);
                queryClient.setQueryData(["user", newData.id], newData);
                toast.success("Identifiants envoyés !", {});
            },
            onError: (error: any) => {
                toast.error("Une erreur est survenue, merci de retenter plus tard");
            },
        }
    )
}



export const useGetWeekUser= (id:number):UseQueryResult<Week, Error>=>{
    return useQuery<Week, Error>(["week", id], ()=>getInfosPlanning(id), {
        staleTime:60000,
        refetchOnWindowFocus:false,
    })
}

export const useGetPlanningThisWeek = (id:number):UseQueryResult<Plage[], Error>=>{
    return useQuery<Plage[],Error>(["planningWeek", id], ()=>getPlageSemCurr(id), {
        staleTime:60000,
        refetchOnWindowFocus:false,
    })
}

export const useGetContrats = (id:number, idCentre:number|null=null):UseQueryResult<Contrat[], Error>=>{
    return  useQuery<Contrat[], Error>(["contrats", id, idCentre], ()=>getContrats(id, idCentre), {
        staleTime:60000,
        refetchOnWindowFocus:false,
    })
}

export const useGetUserReplace = (id:number, idCentre:number|null=null):UseQueryResult<UserDispo[], Error>=>{
    return useQuery<UserDispo[], Error>(["user_dispo", id, idCentre], ()=>getUserReplace(id, idCentre), {
        staleTime:1000,
        refetchOnWindowFocus:false,
        enabled:id!==0
    })
}

export const useGetLieuxFilteredUser = ():UseQueryResult<Lieu[], Error>=>{
    const queryClient = useQueryClient();
    const UserConnected:UserSimple|undefined = queryClient.getQueryData(["user_connected"]);
    const idUser = UserConnected ? UserConnected.id : 0;
    return useQuery<Lieu[], Error>(["lieux_filtered", idUser], ()=>getLieuxFiltered(idUser), {
        enabled:idUser!==0
    })
}

export const useForgotPwd = ()=>{
    return useMutation(
        (data:{username:string})=>{
            return forgot_pwd(data.username);
        }
    )
}

export const useGetUserBornNow=():UseQueryResult<UserBirth[], Error>=>{
    return useQuery<UserBirth[], Error>(["users_births"], ()=>getUsersByCentreBorn(), {

    })
}