import styled from "styled-components";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {LibelleCurrentSmart} from "./LibelleCurrentSmart";
import {ListSmart} from "./ListSmart";
import {ContentSmart, ZoneSearchSmart} from "./styledElt";
import {GrClose} from "react-icons/gr";
import {useGetParams} from "../../../services/hooks";
import {MdLocationOn} from "react-icons/md";
import {useGetAllLieu} from "../../../features/lieu/lieu.hooks";
import {BsPlus} from "react-icons/bs";
import {useGetUser} from "../../../features/user/user.hooks";

interface TimedSelectLieuxScopeCtrlProps{
    className?:string;
}

interface my_params{
    index:string,
    from?:string,
    to?:string,
    lieu?:string,
    fx?:string,
    idCollab?:string,
    vues?:string
}

const TimedSelectLieuxScopeCtrl = ({className}:TimedSelectLieuxScopeCtrlProps)=>{
    const {TabFrom, TabTo, TabFx, TabLieu, TabVues, TabCollab} = useGetParams()
    const refObj = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [libelleCurrent, setLibelleCurrent] = useState('');
    const refSearch = useRef<HTMLInputElement>(null)
    const [search, setSearch] = useState('');
    const LieuxQuery = useGetAllLieu();
    const UserConnectedQuery = useGetUser();

    useEffect(()=>{
        if(LieuxQuery.data){
            if(TabLieu){
                const ids = TabLieu.split('#');
                const Lieux = LieuxQuery.data.filter(l=>ids.indexOf(l.id.toString())!==-1);
                if(Lieux){
                    setLibelleCurrent(Lieux.map((l:Lieu)=>l.libelle).join(', '))
                }
            } else {
                setLibelleCurrent('')
            }
        }
    }, [TabLieu, LieuxQuery.data])
    useEffect(() => {
        function handleClickOutside(event:any) {
            if (refObj.current && !refObj.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refObj]);
    const listLieu = useMemo(()=>{
        let list:Lieu[] = []
        const regex = new RegExp(""+search+"", 'gi');
        if(LieuxQuery.data && UserConnectedQuery.data){
            const params = UserConnectedQuery.data.particularParams;
            let vuesFilter:number[] = [];
            let MyListLieu = LieuxQuery.data;
            if(params && params.hasOwnProperty("VuesFilter")){
                vuesFilter = params.VuesFilter;
            }
            if(vuesFilter.length>0){
                const myListLieuxB:Lieu[] = [];
                LieuxQuery.data.forEach(l=>{
                    const VuesPlanningLieux = l.LieuxVuePlanning.map(lvp=>lvp.VuePlanning).map(vp=>vp.id);
                    let nbCorres = 0;
                    vuesFilter.forEach(vf=>{
                        if(VuesPlanningLieux.indexOf(vf) !== -1){
                            nbCorres++;
                        }
                    })
                    if(nbCorres>0){
                        myListLieuxB.push(l);
                    }
                })

                MyListLieu = myListLieuxB;
            }
            list = MyListLieu.filter(l=>(search === '' || l.libelle.match(regex)) && l.isProduction ).sort((a:Lieu, b:Lieu)=>a.libelle>b.libelle ? 1 : -1)
        }
        return list;
    }, [search, LieuxQuery.data, UserConnectedQuery.data])
    const handleChangeSearch = ()=>{
        if(refSearch.current){
            setSearch(refSearch.current.value);
        }
    }
    const AddLieu = (lieu:Lieu, option:0|1)=>{
        let newTabLieu;
        if(TabLieu && option === 0) {
            const TabLieuTab = TabLieu.split('#')
            if(TabLieuTab.indexOf(lieu.id.toString())!==-1){
                newTabLieu = [...TabLieuTab].filter(s=>s!==lieu.id.toString()).join('#')
            } else {
                newTabLieu = [...TabLieuTab, lieu.id.toString()].join('#');
            }
        } else{
            newTabLieu = lieu.id.toString()
        }
        let myParams: my_params = {
            from: TabFrom!==undefined ? TabFrom : '',
            to: TabTo!==undefined ? TabTo : '',
            lieu: newTabLieu!=='' ? newTabLieu : undefined,
            fx: TabFx,
            idCollab: TabCollab!==undefined ? TabCollab : '',
            vues:TabVues!==undefined ? TabVues : '',
            index: '0'
        };
        if(TabFx===undefined) delete myParams.fx;
        if(TabCollab===undefined) delete myParams.idCollab;
        if(TabFrom===undefined) delete myParams.from;
        if(TabTo===undefined) delete myParams.to;
        if(myParams.lieu===undefined) delete myParams.lieu;
        if(TabVues===undefined) delete myParams.vues;
        const meP = {...myParams}
        setSearch('');
        setSearchParams(meP);
        if(option === 1){
            setOpen(false);
        }
    }
    const Remove = ()=>{
        let myParams: my_params = {
            from: TabFrom!==undefined ? TabFrom : '',
            to: TabTo!==undefined ? TabTo : '',
            lieu: TabLieu!==undefined ? TabLieu : '',
            fx: TabFx!==undefined ? TabFx : '',
            idCollab: TabCollab!==undefined ? TabCollab : '',
            vues:TabVues!==undefined ? TabVues : '',
            index: '0'
        };
        if(TabFx===undefined) delete myParams.fx;
        if(TabCollab===undefined) delete myParams.idCollab;
        if(TabFrom===undefined) delete myParams.from;
        if(TabTo===undefined) delete myParams.to;
        if(myParams.lieu===undefined) delete myParams.lieu;
        if(TabVues===undefined) delete myParams.vues;
        delete myParams.lieu
        const meP = {...myParams}
        setSearchParams(meP);
    }
    return (
        <div className={`element_smart_nav timed_select_url ${className}`} ref={refObj}>
            <LibelleCurrentSmart
                text={libelleCurrent}
                Icon={<MdLocationOn/>}
                themeColor={"Complementary"}
                Actif={libelleCurrent!==''}
                placeholder={"Lieu"}
                Remove={Remove}
                ClickOnMe={()=>setOpen(o=>!o)}
            />
            <ListSmart open={open}>
                <div className={`content-smart`}>
                    <ZoneSearchSmart>
                        <input placeholder={"Recherche"} ref={refSearch} value={search} onChange={handleChangeSearch}/>
                        {search!=='' &&
                            <div className={`close-search`} onClick={()=>setSearch('')}><GrClose/></div>
                        }
                    </ZoneSearchSmart>

                    <ContentSmart>
                        <div className={`one_block`}>
                            <div className="titre">Lieux</div>
                            <div className={"content_block"}>
                                {LieuxQuery.isLoading ? '...' : LieuxQuery.isError ? 'error datas':
                                    <ul>
                                        {listLieu.map((item)=>(
                                            <li key={`one_choice_lieu${item.id}`} className={`one_choice_lieu  ${(TabLieu &&TabLieu?.split('#').indexOf(item.id.toString())!==-1) ? 'selected' : ''}`}>
                                                <div className={`libelle`} onClick={()=>AddLieu(item, 1)}>{item.libelle}</div>
                                                {(TabLieu && TabLieu?.split('#').indexOf(item.id.toString())!==-1) ?
                                                    <div className={`unsel`}>
                                                        <div className={`remove_item`} onClick={()=>AddLieu(item, 0)}><GrClose/></div>
                                                    </div>:
                                                    <div className={`selPlus`}>
                                                        <div className={`selPlus`} >
                                                            <div className={`add_item`} onClick={()=>AddLieu(item, 0)}>
                                                                <BsPlus/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                            </li>
                                        ))}
                                    </ul>
                                }
                            </div>
                        </div>
                    </ContentSmart>
                </div>
            </ListSmart>
        </div>
    )
}

export const TimedSelectLieuxScope = styled(TimedSelectLieuxScopeCtrl)`
    position: relative;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  .one_block{
    font-size: 14px;
    margin-bottom: 10px;
    .titre{
      font-weight: bold;
      font-size: 15px;
      margin-bottom: 10px;
      border-bottom: 1px solid ${props => props.theme.ComplementaryExtraLight};
    }
    .content_block{
      ul{
        margin: 0;
        padding: 0;
        li{
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 5px;
          margin-bottom: 2px;
          .libelle{
            flex-grow: 1;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 0.25rem 0.5rem 0.25rem  0.75rem;
          }
          .unsel{
            padding-right: 3px;
            &:hover{
              cursor: pointer;
            }
            
          }
          .selPlus{
            opacity: 0;
            .add_item{
              padding-left: 8px;
            }
            &:hover{
              cursor: pointer;
            }
          }
          .add_item{
            margin: auto;
            border-radius: 4px;
            transition: padding-left 200ms;
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 22px;
            height: 22px;
            font-size: 22px;
            
            svg{
              margin: auto;
              path{
                color: ${props=>props.theme.Complementary};
              }
            }
            &:hover{
              background: ${props=>props.theme.ComplementaryDark};
              color: ${props=>props.theme.ComplementaryExtraLight};
              svg path{
                color:${props=>props.theme.ComplementaryExtraLight};
              }
            }
          }
          .remove_item{
            border-radius: 50%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 22px;
            height: 22px;
            font-size: 14px;
            svg{
              margin: auto;
              
            }
            &:hover{
              background: ${props=>props.theme.ComplementaryDark};
              color: ${props=>props.theme.ComplementaryExtraLight};
              svg path{
                stroke:${props=>props.theme.ComplementaryExtraLight};
              }
            }
          }
          &:not(.selected):hover{
            .libelle{
              background: ${props => props.theme.ComplementaryMegaLight};
              transition: background-color 300ms;
              font-weight: bold;
            }
            .selPlus{
              opacity: 1;
              .add_item{
                padding-left: 0px;
                transition: padding-left 200ms;
              }

            }
            cursor: pointer;
          }
          &.selected{
            font-weight: bold;
            background: ${props=>props.theme.ComplementaryExtraLight};
            color: ${props=>props.theme.ComplementaryExtraDark};
            border-radius: 12px;
          }
        }
      }
    }
  }
`