import styled from "styled-components";
import React, {useEffect, useMemo, useState} from "react";
import {
    useGetDocumentByUserPage,
    useGetDocumentByUserPageDate
} from "../../../../features/userDocument/userDocument.hooks";
import HeadPage from "../../../../components/organisms/layouts/HeadPage";
import {
    AiOutlineFileExcel,
    AiOutlineFileExclamation,
    AiOutlineFileImage,
    AiOutlineFilePdf,
    AiOutlineFileWord
} from "react-icons/ai";
import TimedSkeletonTable from "../../../../components/atoms/TimedSkeleton/TimedSkeletonTable";
import TimedTable from "../../../../components/molecules/TimedTable/TimedTable";
import {useCentreConnectedData, useDebounce} from "../../../../services/hooks";
import TimedRoundAvatar from "../../../../components/atoms/TimedRoundAvatar/TimedRoundAvatar";
import TimedChipUserAvatarName from "../../../../components/molecules/TimedChipsUser/TimedChipUserAvatarName";
import {useGetAllUser, useSaveConfigPlCGI} from "../../../../features/user/user.hooks";
import {useGetParamsTimed} from "../../../../hooks/useGetParamsTimed";
import {TimedModalCentral} from "../../../../components/molecules/TimedModal/TimedModalCentral";
import {OneItemDoc, UserDocument} from "../../../../features/userDocument/userDocument";
import {InfosModalDoc} from "./InfosModalDoc";


interface GestionDocumentCtrlProps{
    className?:string;
}

const TitlesTab:TitleTable[]=[
    {libelle:'Type', field:'type_doc', fieldSort:'type_doc', date:false, textAlign:'text-center', width:"80px", StyleHead:{textAlign:"center", flexGrow:1}},
    {libelle:'Collab', field:'userConcerned', fieldSort:'user_name', date:false, textAlign:'text-center', width:"210px", StyleHead:{textAlign:"left"}},
    {libelle:'Nom du document', field:'nom', fieldSort:'nom', date:false, textAlign:'text-left', StyleHead:{textAlign:"left"}, StyleCell:{flexGrow:1}},
    {libelle:'Type', field:'type', fieldSort:'type', date:false, textAlign:'', width:"200px"},
    {libelle:'Crée le', field:'creation_at', fieldSort:'creation_at', date:true, textAlign:'', width:"120px"},
]


const TabWord = ['docx', 'doc', 'odt'];
const TabExcel = ['xlsx', 'xls', 'xl'];
const TabPDF = ['PDF', 'pdf'];
const TabImg = ['png', 'jpg', 'jpeg', 'JPEG']

const GestionDocumentCtrl = (props:GestionDocumentCtrlProps)=>{
    const UserQuery = useGetAllUser();
    const {From, To, TabFx, TabCollab} = useGetParamsTimed();
    const [searchNamePerso, setSearchNamePerso] = useState<string>('')
    const searchNamePersoDebounce = useDebounce(searchNamePerso, 900);
    const [idsUser, setIdsUser] = useState<number[]>([])
    const [page, setPage] = useState<number>(1)
    const [totalResults, setTotalResults] = useState<number>(0)
    const DocumentsQuery = useGetDocumentByUserPageDate(idsUser, From ? From.toISOString().slice(0,10) : '', To ? To.toISOString().slice(0,10) : '', 1, searchNamePersoDebounce)
    const [DocCurrent, setDocCurrent] = useState<UserDocument|null>(null)
    const [openMod, setOpenMod] = useState<boolean>(false)
    const switchOpenMod = ()=>{
        setOpenMod(e=>!e)
    }
    useEffect(()=>{
        if(DocumentsQuery.data){
            setTotalResults(DocumentsQuery.data.total)
        }
    }, [DocumentsQuery.data])
    useEffect(()=>{
        if(UserQuery.data) {
            if (TabFx) {
                const UsersConcerned = UserQuery.data.filter(u=> TabFx.indexOf((u.Fonction.id).toString()) !==-1)
                setIdsUser(UsersConcerned.map(u=>u.id))
            } else if (TabCollab) {
                setIdsUser(TabCollab.map(c => parseInt(c)))
            } else {
                setIdsUser([])
            }
        }
    }, [TabCollab, TabFx, UserQuery.data])
    const getIconFile = (fileUrl:string)=>{
        const TabA = fileUrl.split('/');
        const nb = TabA.length;
        const fileName = TabA[nb-1];
        const TabB = fileName.split('.');
        const nbB = TabB.length;
        const ext = TabB[nbB-1];
        if(TabWord.indexOf(ext)!==-1){
            return <AiOutlineFileWord style={{fontSize:"18px"}}/>;
        } else if(TabExcel.indexOf(ext)!==-1){
            return <AiOutlineFileExcel style={{fontSize:"18px"}}/>;
        } else if(TabPDF.indexOf(ext)!==-1){
            return <AiOutlineFilePdf style={{fontSize:"18px"}}/>;
        } else if(TabImg.indexOf(ext)!==-1){
            return <AiOutlineFileImage style={{fontSize:"18px"}}/>;
        } else {
            return <AiOutlineFileExclamation style={{fontSize:"18px"}}/>;
        }
    }
    const lineFiltered = useMemo(()=>{
        if(!DocumentsQuery.data){
            return []
        } else {
            const list = DocumentsQuery.data.list
            const myList:OneItemDoc[]=[]
            const regex = new RegExp(""+searchNamePerso+"", 'gi');
            list.map(d=>{
                const nameOf = `${d.User?.prenom} ${d.User?.nom}`
                if((!d.namePerso && d.documentUrl.match(regex)) || (d.namePerso && d.namePerso.match(regex)) || searchNamePerso === '') {
                    myList.push({
                        id: d.id,
                        type_doc: getIconFile(d.documentUrl),
                        userConcerned: <TimedChipUserAvatarName isBold
                                                                Round={<TimedRoundAvatar size={"other"} sizeP={"28px"}
                                                                                         src={d.User ? d.User.avatarUrl : ''}/>}
                                                                libelle={nameOf}/>,
                        user_name: d.User?.nom || '',
                        nom: d.namePerso,
                        type: d.Type.libelle,
                        creation_at: d.creationAt,
                        doc_url: d.documentUrl
                    })
                }
                return d;

            })
            return myList
        }
    },  [DocumentsQuery.data])
    const handleClick = (l:OneItemDoc)=>{
        const MyDoc = DocumentsQuery.data?.list.find(d=>d.id === l.id)
        if(MyDoc) {
            setDocCurrent(MyDoc);
            setOpenMod(o=>{
                if(!o){
                    return true;
                }
                return false;
            });
        }
    }
    return (
        <div className={`gestion_documents ${props.className}`}>
            <HeadPage titre={"Gestion des documents"}/>
            <div className={`zearch_zone`}>
                <div className={'zone_input'}>
                    <input placeholder={"Taper votre recherche sur le nom du document ici"} type={"text"} value={searchNamePerso === 'x' ? '' : searchNamePerso} onChange={(e)=>setSearchNamePerso(e.target.value)}/>
                </div>
                <div className={"Zone_total"}>{totalResults}</div>
            </div>
            {(DocumentsQuery.isLoading) ?
                <TimedSkeletonTable nbColumns={6} nbLines={5} /> :
                <>
                    <div className={"wrap-tab"}>
                        <TimedTable
                            Titles={TitlesTab}
                            sortable={true}
                            themeColor={"Primary"}
                            Outline={true}
                            Lines={lineFiltered ? lineFiltered : []}
                            scrollable={true}
                            loading={false}
                            maxHeight={"90%"}
                            onClickLine={handleClick}
                        />
                    </div>
                </>
            }
            <TimedModalCentral closeMe={()=>setOpenMod(false)} open={openMod} Pending={false} titre={"Document"} Wd={500}>
                <InfosModalDoc Doc={DocCurrent} closeMe={()=>setOpenMod(false)}/>
            </TimedModalCentral>
        </div>
    )
}

const GestionDocument = styled(GestionDocumentCtrl)`
  height: 100%;
  padding: 1% 2%;
  display: flex;
  flex-direction: column;
  .wrap-tab{
    flex-grow: 1;
  }
  .zearch_zone{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-bottom: 10px;
    align-items: stretch;
    .zone_input{
      flex-grow: 1;
      input{
         width: 100%;
        height: 38px;
        padding-left: 10px;
        border-radius: 4px;
        border: solid 1px ${props => props.theme.PrimaryMegaLight}
      }
    }
    .Zone_total{
      padding: 0 15px;
      font-size: 18px;
      color:${props=>props.theme.Primary};
      font-weight: bold;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`

export default GestionDocument;