import styled from "styled-components";
import {memo, ReactNode} from "react";
import AvatAno from "../../../assets/images/Unisex-Person-with-Glasses.png";

interface TimedUserAvatarCtrlProps{
    className?:string;
    User?:User|UserSimple;
    CustomImg?:ReactNode;
    CustomText?:ReactNode;
    CustomStylePic?:{width:number, height:number, border?:string};
    CustomStyleText?:{width?:number, color?:string, border?:string, background?:string};
    ClickAll?:()=>void;
    ClickText?:()=>void;
    LeftAddInit?:boolean;
    RightAddInit?:boolean;
    isInactif?:boolean;
    isInverseName?:boolean;
    isAddFonction?:boolean;
    CustomSize?:string;
    CustomFontSize?:string;

}

const TimedUserAvatarCtrl = ({className, ...props}:TimedUserAvatarCtrlProps)=>{
    const DefaultAvatarSize = props.CustomSize ? props.CustomSize : props.isAddFonction ? "34px" : "28px";
    const styleAvatar = props.CustomStylePic ? {...props.CustomStylePic, borderTopLeftRadius:"4px", borderBottomLeftRadius:"4px"} : {width:DefaultAvatarSize, height:DefaultAvatarSize, borderTopLeftRadius:"4px", borderBottomLeftRadius:"4px"}
    const OriginalStyleText = {padding:"0.2rem 0.4rem", borderTopRightRadius:"4px", borderBottomRightRadius:"4px", height:props.CustomStyleText ? props.CustomStylePic?.height||DefaultAvatarSize : DefaultAvatarSize}
    const styleText = props.CustomStyleText ? {...props.CustomStyleText, ...OriginalStyleText, flexGrow:1} : {...OriginalStyleText, width:"auto", height:props.CustomStylePic ? props.CustomStylePic.height : DefaultAvatarSize, flexGrow:1}
    const handleError = (e:any)=>{
        e.target.src = AvatAno;
    }
    const handleClickAll = ()=>{
        if(props.ClickAll){
            props.ClickAll();
        }
    }
    return (
        <div className={`Timed_user_avatar ${className} ${props.ClickAll || props.ClickText ? 'clickable' : ''}`} onClick={handleClickAll}>
                <div className={`Avatar_place`} style={styleAvatar}>
                    {props.User ?
                        <img src={`${process.env.REACT_APP_API_URL_ROOT}${props.User.avatarUrl}`} alt={`pic ${props.User.nom}`} onError={handleError}/>:
                        props.CustomImg ? props.CustomImg : ''
                    }
                    {props.isInactif && <div className={'inactif'}/>}
                </div>
                <div className={`Text_place`} style={styleText}>
                    {props.CustomText ?
                        props.CustomText:
                        <>
                        <div className={`in_text`}>
                            <span>{props.User ? `${props.LeftAddInit ? `(${props.User.initials}) ` : ''}${props.isInverseName ?  props.User.nom : props.User.prenom} ${props.isInverseName ?  props.User.prenom : props.User.nom}${props.RightAddInit ? ` (${props.User.initials})` : ''}` : ''}</span>
                        </div>
                            {props.isAddFonction && props.User &&
                                <div className={`in_text`}>
                                    <span className={"fonction"}>{props.User?.Fonction.libelle}</span>
                                </div>
                            }
                        </>

                    }
                </div>
        </div>
    )
}

const TimedUserAvatarStyled = styled(TimedUserAvatarCtrl)`
    display: flex;
    justify-content: flex-start;
  .fonction{
    display: block;
    color: ${props=>props.theme.Primary};
  }
  &.clickable{
    &:hover{
      cursor: pointer;
      .in_text{
          text-decoration: underline;
      }
    }
    
  }
  .Avatar_place{
    overflow: hidden;
    flex-shrink: 0;
    position: relative;
    .inactif{
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      top: 0;
      background: rgba(255,255,255,0.6);
    }
    img{
      width: 100%;
      height: auto;
    }
  }
  .in_text{
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
    overflow: hidden;
    font-size: ${props=>props.CustomFontSize ? props.CustomFontSize : "13px"};
  }
  .Text_place{
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
  }
`

export const TimedUserAvatar = memo(TimedUserAvatarStyled)