import {
    useInfiniteQuery,
    UseInfiniteQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
    UseQueryResult
} from "react-query";
import {
    AddCreneau, cancelCreneauPris, changeInfos, changeRemplacant, changeTitulaire,
    freeCreneau,
    getCreneaux, getCreneauxFullParams, getCreneauxFuture, getCreneauxNonPris, getCreneauxPages, getCreneauxToValid,
    getSuggestions,
    majCreneau, ReturnCrenPage,
    trashCreneau,
    valideCreneau
} from "./creneau.services";
import {toast} from "react-toastify";

export const useGetCreneaux = (idCentre:number|null):UseQueryResult<Creneau[], Error>=>{
    return useQuery<Creneau[], Error>(["creneaux", idCentre], ()=>getCreneaux(idCentre), {
        staleTime:60000,
    })
}
export const useGetCreneauxFullParams = (Params:any|null):UseQueryResult<Creneau[], Error>=>{
    return useQuery<Creneau[], Error>(["creneaux_fp", Params], ()=>getCreneauxFullParams(Params), {
        staleTime:60000,
        enabled:Params!==null
    })
}

export const useGetCreneauxFuture = (idCentre:number|null=null):UseQueryResult<Creneau[], Error>=>{
    return useQuery<Creneau[], Error>(["creneaux_futures", idCentre], ()=>getCreneauxFuture(idCentre), {
        staleTime:60000,
    })
}

export const useGetCreneauxNonPris = (idCentre:number|null=null):UseQueryResult<Creneau[], Error>=>{
    return useQuery<Creneau[], Error>(["creneaux_nonpris", idCentre], ()=>getCreneauxNonPris(idCentre), {
        staleTime:60000,
    })
}

export const useGetCreneauxToValide = (idCentre:number|null=null):UseQueryResult<Creneau[], Error>=>{
    return useQuery<Creneau[], Error>(["creneaux_tovalid",idCentre], ()=>getCreneauxToValid(idCentre), {
        staleTime:60000,
    })
}

export const useGetCreneauxInfinite = (params:any|null|undefined=null):UseInfiniteQueryResult<ReturnCrenPage, Error>=>{
    return useInfiniteQuery<ReturnCrenPage, Error>(["creneaux_pagine"], ({pageParam=`creneaus?page=1`})=>getCreneauxPages(pageParam, params),  {
        getNextPageParam: (lastPage, allPages) =>{return lastPage.nextPage},
        enabled:params!==undefined
    })
}

export const useGetCreneauxSuggestions = (idCentre:number|null):UseQueryResult<CreneauSuggestion[], Error>=>{

    return useQuery<CreneauSuggestion[], Error>(["creneaux_sugg", idCentre], ()=>getSuggestions(idCentre), {
        staleTime:60000,
    })
}

export const useAddCreneau = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (data:CreneauFD)=>{
            return AddCreneau(data);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["creneaux"]);
                queryClient.invalidateQueries(["creneaux_sugg"]);
                queryClient.invalidateQueries(["creneaux_nonpris"]);
                queryClient.invalidateQueries(["creneaux_tovalid"]);
                queryClient.invalidateQueries(["creneaux_futures"]);
                queryClient.invalidateQueries(["creneaux_fp"]);
                queryClient.invalidateQueries(["absence_creneaus"]);
                queryClient.invalidateQueries(["absence_creneaus_date"]);
                queryClient.invalidateQueries(["plages_week"]);
                toast.success("Creneau ajouté")
            }, onError:(e:any)=>{
                toast.error("une erreur est survenu merci de retenter plus tard")
            }
        }
    )
}

export const useMajCreneau = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (data:CreneauFD)=>{
            return  majCreneau(data);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["creneaux"]);
                queryClient.invalidateQueries(["creneaux_nonpris"]);
                queryClient.invalidateQueries(["creneaux_tovalid"]);
                queryClient.invalidateQueries(["creneaux_futures"]);
                queryClient.invalidateQueries(["creneaux_fp"]);
                toast.success("Creneau modifié")
            }, onError:()=>{
                toast.error("une erreur est survenu merci de retenter plus tard")
            }
        }
    )
}

export const useValideCreneau = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (id:number)=>{
            return  valideCreneau(id);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["creneaux"]);
                queryClient.invalidateQueries(["creneaux_nonpris"]);
                queryClient.invalidateQueries(["creneaux_tovalid"]);
                queryClient.invalidateQueries(["creneaux_futures"]);
                queryClient.invalidateQueries(["creneaux_fp"]);
                toast.success("Creneau validé")
            }, onError:()=>{
                toast.error("une erreur est survenu merci de retenter plus tard")
            }
        }
    )
}

export const useFreeCreneau = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (id:number)=>{
            return  freeCreneau(id);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["creneaux"]);
                queryClient.invalidateQueries(["creneaux_nonpris"]);
                queryClient.invalidateQueries(["creneaux_tovalid"]);
                queryClient.invalidateQueries(["creneaux_futures"]);
                queryClient.invalidateQueries(["creneaux_fp"]);
                toast.success("Creneau libéré")
            }, onError:()=>{
                toast.error("une erreur est survenu merci de retenter plus tard")
            }
        }
    )
}

export const useCancelCreneauPris = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (id:number)=>{
            return cancelCreneauPris(id);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["creneaux"]);
                queryClient.invalidateQueries(["creneaux_nonpris"]);
                queryClient.invalidateQueries(["creneaux_tovalid"]);
                queryClient.invalidateQueries(["creneaux_futures"]);
                queryClient.invalidateQueries(["creneaux_fp"]);
                toast.success("Creneau libéré")
            }, onError:()=>{
                toast.error("une erreur est survenu merci de retenter plus tard")
            }
        }
    )
}

export const useTrashCreneau = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (id:number)=>{
            return  trashCreneau(id);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["creneaux"]);
                queryClient.invalidateQueries(["creneaux_nonpris"]);
                queryClient.invalidateQueries(["creneaux_tovalid"]);
                queryClient.invalidateQueries(["creneaux_futures"]);
                queryClient.invalidateQueries(["creneaux_fp"]);
                toast.success("Creneau supprimé")
            }, onError:(e:any)=>{
                toast.error("une erreur est survenu merci de retenter plus tard")
            }
        }
    )
}

export const useChangeTitulaire = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (data:CreneauFD)=>{
            return  changeTitulaire(data);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["creneaux"]);
                queryClient.invalidateQueries(["plages"]);
                queryClient.invalidateQueries(["plages_week"]);
                queryClient.invalidateQueries(["creneaux_nonpris"]);
                queryClient.invalidateQueries(["creneaux_tovalid"]);
                queryClient.invalidateQueries(["creneaux_futures"]);
                queryClient.invalidateQueries(["creneaux_fp"]);
                toast.success("Creneau modifié")
            }, onError:(e:any)=>{
                toast.error("une erreur est survenu merci de retenter plus tard")
            }
        }
    )
}

export const useChangeRemplacant = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (data:CreneauFD)=>{
            return  changeRemplacant(data);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["creneaux"]);
                queryClient.invalidateQueries(["creneaux_nonpris"]);
                queryClient.invalidateQueries(["creneaux_tovalid"]);
                queryClient.invalidateQueries(["creneaux_futures"]);
                queryClient.invalidateQueries(["creneaux_fp"]);
                queryClient.invalidateQueries(["plages"]);
                queryClient.invalidateQueries(["plages_week"]);
                toast.success("Creneau modifié")
            }, onError:(e:any)=>{
                toast.error("une erreur est survenu merci de retenter plus tard")
            }
        }
    )
}

export const useChangeInfos = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (data:CreneauFD)=>{
            return  changeInfos(data);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["creneaux"]);
                queryClient.invalidateQueries(["creneaux_nonpris"]);
                queryClient.invalidateQueries(["creneaux_tovalid"]);
                queryClient.invalidateQueries(["creneaux_futures"]);
                queryClient.invalidateQueries(["creneaux_fp"]);
                queryClient.invalidateQueries(["plages"]);
                queryClient.invalidateQueries(["plages_week"]);
                toast.success("Creneau modifié")
            }, onError:(e:any)=>{
                toast.error("une erreur est survenu merci de retenter plus tard")
            }
        }
    )
}