import React, {useEffect, useMemo, useState} from "react";
import styled, {useTheme} from "styled-components";
import {TitrePage} from "../../../../assets/styles/elements";
import TimedIconButton from "../../../../components/atoms/TimedIconButton/TimedIconButton";
import {BsLightbulb} from "react-icons/bs";
import {AiOutlineArrowDown, AiOutlineArrowUp, AiOutlineFileExcel, AiOutlineFilePdf, AiOutlinePlus} from "react-icons/ai";
import TimedChips from "../../../../components/atoms/TimedChips/TimedChips";
import {useGetCreneauxFullParams} from "../../../../features/creneau/creneau.hooks";
import CardCreneau from "../components/CardCreneau";
import {GrFormClose} from "react-icons/gr";
import {FormNewCreneau} from "../../../Forms/FormNewCreneau";
import SuggestionsCreneaux from "../components/SuggestionsCreneaux";
import TimedSkeletonList from "../../../../components/atoms/TimedSkeleton/TimedSkeletonList";
import {useLocation, useNavigate} from "react-router-dom";
import {useSortableData} from "../../../../hooks/useSortableData";
import {TimedTotalLine} from "../../../../components/molecules/TimedTotalLine/TimedTotalLine";
import {useModal} from "../../../../hooks/useModal";
import {MdOutlineEmail} from "react-icons/md";
import ModalSendEmailRempl from "../../../Modaux/ModalSendEmailRempl";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import {ConvertBase5} from "../../../../components/functions/TimesFunctions";
import {usePDF} from "@react-pdf/renderer";
import {CreneauxEnCoursPDF} from "./CreneauxEnCoursPDF";
import {useQueryClient} from "react-query";
import {useCentreConnectedData} from "../../../../services/hooks";
import {useGetOneCentre} from "../../../../features/centre/centre.hooks";
import {TimedModalCentral} from "../../../../components/molecules/TimedModal/TimedModalCentral";
import {useGetParamsTimed} from "../../../../hooks/useGetParamsTimed";


interface CreneauxEnCoursCtrlProps{
    className?:string;
    supervisor?:boolean;
}

interface CustomizedState {
    from: string
}

export interface OneItemChips{
    id:number;
    libelle:string;
    themeColor:string;
}

const TabChipsChrono:OneItemChips[] = [
    {id:0, libelle:"Passé", themeColor:"Primary"},
    {id:1, libelle:"A venir", themeColor:"Primary"}
]

export const TabChipsStatut:OneItemChips[] = [
    {id:0, libelle:"A prendre", themeColor:"Complementary"},
    {id:1, libelle:"Pris", themeColor:"Secondary"},
    {id:2, libelle:"Validés", themeColor:"Tertiary"}
]

const ChronosDefault:OneItemChips[] = [
    {id:1, libelle:"A venir", themeColor:"Primary"}
]

const StatutDefault:OneItemChips[] = [
    {id:0, libelle:"A prendre", themeColor:"Complementary"},
]

interface OneTitlesHead{
    libelle:string;
    fieldSort:string;
    width:string;
}
const TitlesHeads:OneTitlesHead[]=[
    {libelle:"Médecin", fieldSort:"nomMed", width:"190px"},
    {libelle:"Lieu", fieldSort:"LieuLib", width:"160px"},
    {libelle:"Date", fieldSort:"dateCren", width:"75px"},
    {libelle:"Seg", fieldSort:"Seg", width:"70px"},
    {libelle:"HD", fieldSort:"HD", width:"50px"},
    {libelle:"HF", fieldSort:"HF", width:"50px"},
    {libelle:"Rempl.", fieldSort:"nomMedR", width:"190px"},
    {libelle:"Niv.", fieldSort:"nivo", width:"46px"},
    {libelle:"Aide", fieldSort:"isAide", width:"61px"},
]

export interface OneLineCren{
    Creneau:Creneau,
    nomMed:string;
    LieuLib:string;
    dateCren:Date;
    Seg:number;
    HD:number;
    HF:number;
    nomMedR:string;
    nivo:number;
    isAide:number;
}

const CreneauxEnCoursCtrl=({className, supervisor}:CreneauxEnCoursCtrlProps)=>{
    const centre = useCentreConnectedData();
    const CentreQuery = useGetOneCentre(centre||-1);
    const {TabLieu} = useGetParamsTimed()
    const location = useLocation();
    const [openNew, setOpenNew] = useState<boolean>(false);
    const [newCurr, setNewCurr] = useState<string>('new');
    const [chronos, setChronos] = useState<OneItemChips[]>(ChronosDefault);
    const [status, setStatus] = useState<OneItemChips[]>(StatutDefault);
    const [crenDup, setCrenDup] = useState<Creneau|null>(null)
    const [emailSendable, setEmailSendable] = useState<boolean>(false);
    const [params, setParams] = useState<any>(!supervisor ? {"creneauAt[after]":(new Date()).toISOString().slice(0,10), "isTreat":0, "isAffect":0} : {"creneauAt[after]":(new Date()).toISOString().slice(0,10), "isTreat":0, "isAffect":0, Centre:centre})
    const {isLoading, data:creneaux} = useGetCreneauxFullParams(params);
    const {open:openSE, toggle:toggleSE} = useModal();

    const [csvData, setCsvData] = useState<any[]>([])
    const [titlePDF, setTitlePDF] = useState('');
    const queryClient = useQueryClient();
    const userConnected:User|undefined = queryClient.getQueryData("user_connected");
    const [centreName, setCentreName] = useState<string>('Timed')
    useEffect(()=>{
        if(supervisor && centre){
          setParams({"creneauAt[after]":(new Date()).toISOString().slice(0,10), "isTreat":0, "isAffect":0, Centre:centre})
        }
    }, [supervisor, centre])
    useEffect(()=>{
        if(!supervisor) {
            if (userConnected) setCentreName(userConnected.Centre.name)
        } else if(CentreQuery.data) {
            setCentreName(CentreQuery.data.name);
        }
    }, [userConnected, supervisor, CentreQuery.data])
    useEffect(()=>{
        const state = location.state as CustomizedState;
        if(state && state.from === 'creneaux_dashboard'){
            setStatus([{id:1, libelle:"Pris", themeColor:"Secondary"}])
        }
    },[location])

    const ListCreneauStatut = useMemo(()=>{
        let list:OneLineCren[] = [];
        if(creneaux){
            const Today = new Date();
            creneaux.map(c=>{
                const statutCren = !!c.treatAt ? 2 : !!c.UserAffected ? 1 : 0;
                const chronoCren = new Date(c.creneauAt) < Today ? 0 : 1;
                const idsStatus = status.map(s=>s.id);
                const idsChronos = chronos.map(c=>c.id);
                if(idsChronos.indexOf(chronoCren)!==-1 && idsStatus.indexOf(statutCren)!==-1 && (!TabLieu || TabLieu.indexOf(c.Lieu.id.toString()))!==-1){
                    list.push({
                        Creneau:c,
                        nomMed:c.UserReplaced.nom,
                        LieuLib:c.Lieu.libelle,
                        dateCren:new Date(c.creneauAt),
                        Seg:c.heureDebut,
                        HD:c.heureDebut,
                        HF:c.heureFin,
                        nomMedR:c.UserAffected ? c.UserAffected.nom : '',
                        nivo:c.niveau,
                        isAide:c.aide ? 1 : 0,
                    });
                }
                return c
            })
        }
        return list;
    }, [creneaux, status, chronos, TabLieu])
    useEffect(()=>{
        if(creneaux){
            const Today = new Date();
            const CreneauxAprendre = creneaux.filter(c=>new Date(c.creneauAt) > Today && !c.treatAt && !c.UserAffected);
            setEmailSendable(CreneauxAprendre.length >0);
        }
    }, [creneaux])
    const navigate = useNavigate();
    const { search } = useLocation();
    const HandleClickSugg = ()=>{
        navigate("/timed_application/absences_med"+search)
    }
    const handleclickAdd = ()=>{
        setCrenDup(null);
        if(openNew) {
            setOpenNew(false);
            setTimeout(() => {
                setNewCurr('new');
                setOpenNew(true);
            }, 500)
        } else {
            setNewCurr('new');
            setOpenNew(true);
        }
    }
    const SwitchChronos = (item:OneItemChips, db:boolean=false)=>{
        //const Exist = [...chronos].find(i=>i.id===item.id);
        setChronos([item]);
        let paramsX:any
        if(item.id === 0){
            paramsX = {...params,"creneauAt[after]":"2000-01-01", "creneauAt[before_strictly]":(new Date()).toISOString().slice(0,10)}
        } else {
            paramsX = {...params, "creneauAt[after]":(new Date()).toISOString().slice(0,10), "creneauAt[before_strictly]":"2099-01-01"};
        }
        if(supervisor){
            paramsX.Centre = centre;
        }
        setParams(paramsX);

    }
    const SwitchStatut = (item:OneItemChips, db:boolean=false)=>{
        //const Exist = [...status].find(i=>i.id===item.id);
        setStatus([item]);
        let paramsX:any;
        if(item.id === 0){
            paramsX = {...params, "isAffect":0, "isTreat":0}
        } else if(item.id === 1){
            paramsX = {...params, "isAffect":1, "isTreat":0}
        } else {
            paramsX = {...params, "isAffect":1, "isTreat":1}
        }
        if(supervisor){
            paramsX.Centre = centre;
        }
        setParams(paramsX);

    }
    const handleForDuplicate = (Creneau:Creneau)=>{
        setNewCurr('new');
        setCrenDup(Creneau);
        if(!openNew){
            setOpenNew(true);
        }
    }
    const handleSendEmail = ()=>{
        toggleSE();
    }
    useEffect(()=>{
        let tabCSV:any[]=[];
        ListCreneauStatut.map(item=>{
            tabCSV.push({
                Medecin_A_Remplacer:item.Creneau.UserReplaced.fullName,
                Lieu:item.LieuLib,
                Date:item.dateCren.toLocaleDateString(),
                Seg:(item.HF - item.HD) > 60 ? 'Journée' : item.HD < 144 ? 'Matin' : 'Après-midi',
                Heure_Debut:ConvertBase5(item.HD),
                Heure_Fin:ConvertBase5(item.HF),
                Medecin_Remplacant:item.Creneau.UserAffected ? item.Creneau.UserAffected.fullName : 'non affecté',
                Niveau:item.nivo,
                Aide:item.isAide ? 'Avec' : 'Sans'
            })
            return item;
        })
        setCsvData(tabCSV)
    },[ListCreneauStatut])
    const exportToCSV = (csvData: Object[], fileName: string) => {
        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, fileName + fileExtension);
    };
    const handleClickExportExcel = ()=>{
        if(csvData.length >0){
            const Today = new Date();
            const TodayStr = Today.toLocaleDateString().replace("/", "");
            const nameFile = "ExportCreneaux_"+TodayStr
            exportToCSV(csvData, nameFile)
        }
    }
    const theme = useTheme();
    const [instance, updateInstance] = usePDF({
        document:(
            <CreneauxEnCoursPDF theme={theme} list={ListCreneauStatut} date={new Date().toLocaleDateString()} title={titlePDF} CentreName={centreName}/>
        )
    })
    useEffect(()=>{
        const libChronos = chronos.map(i=>i.libelle).join(", ")
        const libStatus = status.map(i=>i.libelle).join(", ")
        setTitlePDF(`${libChronos} / ${libStatus}`);
    }, [chronos, status])
    useEffect(()=>{
        updateInstance();
    }, [ListCreneauStatut, titlePDF])
    const {lines, requestSort, sortConfig} = useSortableData(ListCreneauStatut);
    const handleClickExport = ()=>{
        if(ListCreneauStatut.length>0 && !instance.loading && !instance.error && instance.url){
            window.open(instance.url);
        }
    }
    return (
        <div className={`GestionCreneaux ${className}`}>
            <TitrePage>Gestion Créneaux</TitrePage>

            {(isLoading) ?
                <TimedSkeletonList qty={5} nbBar={5}/> :
                <>
                    <div className={`wrap-line-action`}>
                        <div className={`wrap-chips`}>
                            <div className={`wrap-chronos wrappy-chips`}>
                                {TabChipsChrono.map((item: OneItemChips, idx: number) => (
                                    <TimedChips
                                        key={`oneFilterChronos${idx}`}
                                        themeColor={item.themeColor}
                                        choices={chronos}
                                        MyChoice={item}
                                        setChoice={SwitchChronos}
                                        dbClickChoice={SwitchChronos}
                                    />
                                ))}
                            </div>
                            <div className={`wrap-statut wrappy-chips`}>
                                {TabChipsStatut.map((item: OneItemChips, idx: number) => (
                                    <TimedChips
                                        key={`oneFilterStatut${idx}`}
                                        themeColor={item.themeColor}
                                        choices={status}
                                        MyChoice={item}
                                        setChoice={SwitchStatut}
                                        dbClickChoice={SwitchStatut}
                                    />
                                ))}
                            </div>
                        </div>

                        <div className={`wrap-action`}>
                            <TimedIconButton
                                size={"medium"}
                                themeColor={"Primary"}
                                Icon={<BsLightbulb/>}
                                toolTip={"Suggestions"}
                                onClick={HandleClickSugg}
                                BackAlw={true}
                                isActive={false}
                            />
                            <TimedIconButton
                                size={"medium"}
                                themeColor={"Primary"}
                                Icon={<AiOutlinePlus/>}
                                toolTip={"Ajout créneaux"}
                                onClick={handleclickAdd}
                                BackAlw={true}
                                isActive={false}
                            />
                            {emailSendable &&
                                <TimedIconButton
                                    size={"medium"}
                                    themeColor={"Primary"}
                                    Icon={<MdOutlineEmail/>}
                                    toolTip={"Envoyé aux remplaçants"}
                                    onClick={handleSendEmail}
                                    BackAlw={true}
                                    isActive={false}
                                />
                            }
                            <TimedIconButton
                                size={"medium"}
                                themeColor={"Primary"}
                                Icon={<AiOutlineFileExcel/>}
                                toolTip={"Export Xl"}
                                onClick={handleClickExportExcel}
                                BackAlw={true}
                                isActive={false}
                            />
                            <TimedIconButton
                                size={"medium"}
                                themeColor={"Primary"}
                                Icon={<AiOutlineFilePdf/>}
                                toolTip={"Export PDF"}
                                onClick={handleClickExport}
                                BackAlw={true}
                                isActive={false}
                            />
                        </div>
                    </div>
                    <TimedTotalLine
                        isLoading={isLoading}
                        total={lines.length}
                        padding={"0 0 0.5rem 0"}
                    />
                    <div className={`wrap-page`}>
                        <div className={`head-creneaux`}>
                            <div className={`wrap-heads`}>
                                {TitlesHeads.map((item: OneTitlesHead, idx: number) => (
                                    <div className={`one-head`} style={{width:item.width}} key={`onTH${idx}`}>
                                        <div className={`switchHead`} onClick={()=>requestSort(item.fieldSort)}>
                                            {item.libelle}
                                            {(sortConfig && sortConfig.key === item.fieldSort) ?
                                                sortConfig.direction === 'ascending' ? <span className="ms-2"><AiOutlineArrowDown/></span> :
                                                    <span className="ms-2"><AiOutlineArrowUp/></span>:
                                                ''
                                            }
                                        </div>
                                    </div>
                                ))
                                }
                                <div style={{width:"290px"}}></div>
                            </div>
                        </div>
                        <div className={`content-creneaux`}>
                            {lines.map((c:OneLineCren, idx:number)=>(
                                <CardCreneau key={`oneCren${idx}`} Creneau={c.Creneau} setCrenDup={handleForDuplicate} supervisor={supervisor}/>
                            ))}
                        </div>
                        {/*<div className={`content-add`}>*/}
                        {/*    <div className={`header-content-add`}>*/}
                        {/*        <div className={`titre-add isNew`}>*/}
                        {/*            <div className={`name-add`} onClick={handleclickAdd}>Nouveau Creneau</div>*/}
                        {/*            {openNew && newCurr === 'new' &&*/}
                        {/*                <div className={`closeNew`} onClick={()=>setOpenNew(false)}><GrFormClose/></div>*/}
                        {/*            }*/}
                        {/*        </div>*/}
                        {/*        <div className={`titre-add isSugg`}>*/}
                        {/*            <div className={`name-add`} onClick={HandleClickSugg}>Suggestions</div>*/}
                        {/*            {openNew && newCurr !== 'new' &&*/}
                        {/*                <div className={`closeNew`} onClick={()=>setOpenNew(false)}><GrFormClose/></div>*/}
                        {/*            }*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className={`body-content-add ${openNew && 'open'}`}>*/}
                        {/*        {newCurr === 'new' ?*/}
                        {/*            <FormNewCreneau Creneau={crenDup} supervisor={supervisor}/>:*/}
                        {/*            <SuggestionsCreneaux supervisor={supervisor}/>*/}
                        {/*        }*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <TimedModalCentral closeMe={()=>setOpenNew(false)} open={openNew} Pending={false} Wd={600} titre={"Nouveau Créneau"} noOverFlowHidden>
                            <FormNewCreneau
                                closeMe={()=>setOpenNew(false)}
                                ActeDefault={crenDup?.Acte||undefined}
                                LieuDefault={crenDup?.Lieu}
                                dateDefault={crenDup ? new Date(crenDup.creneauAt.slice(0,10)+" 12:00:00") : undefined}
                                MedecinDefault={crenDup?.UserReplaced}
                                HDDefault={crenDup ?  ConvertBase5(crenDup.heureDebut) : undefined}
                                HFDefault={crenDup ?  ConvertBase5(crenDup.heureFin) : undefined}
                            />
                        </TimedModalCentral>
                    </div>
                </>
            }
            {openSE &&
                <ModalSendEmailRempl
                    Close={toggleSE}
                />
            }
        </div>
    )
}

const CreneauxEnCours = styled(CreneauxEnCoursCtrl)`
  padding: 0.7rem;
  .head-creneaux{
    background: white;
    border-radius: 12px;
    margin-bottom: 7px;
    //height: 32px;
    padding: 0.25rem 0.5rem 0.25rem 53px;
    .wrap-heads{
      width: 100%;
      display: flex;
      justify-content: flex-start;
      gap:10px;
      .one-head{
        font-size: 14px;
        flex-shrink: 0;
        //background: #ccc;
        //font-weight: bold;
        padding: 0.25rem 0;
        .switchHead{
          font-size: 14px;
          text-align: center;
          color:${props=>props.theme.NeutreDark};
          &:hover{
            cursor: pointer;
          }
        }
      }
    }
  }
  .wrap-page{
    height: 72vh;
    display: flex;
    justify-content:start;
    flex-direction: column;
    .content-creneaux{
      flex-grow: 1;
    }
    .content-creneaux{
      flex-grow: 1;
      
      overflow-y: auto;
    }
    .content-add{
      display: flex;
      justify-content:start;
      flex-direction: column;
      width: 100%;
      padding-top: 16px;
      .header-content-add{
        display: flex;
        justify-content: start;
        .titre-add{
          padding: 0.25rem 0.5rem;
          width: 49.5%;
          height: 30px;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
          display: flex;
          justify-content: start;
          align-items: center;
          &:hover{
            cursor: pointer;
          }
          .name-add{
            flex-grow: 1;
          }
          .closeNew{
            &:hover{
              cursor: pointer;
            }
          }
          &.isNew{
            background: ${props=>props.theme.PrimaryExtraLight};
            color:${props=>props.theme.PrimaryLight};
            font-weight: bold;
            margin-right: 1%;
          }
          &.isSugg{
            background: ${props=>props.theme.ComplementaryExtraLight};
            color:${props=>props.theme.ComplementaryLight};
            font-weight: bold;
          }
        }
        
      }
      .body-content-add{
        height: 0;
        width: 100%;
        transition: height 0.3s;
        overflow: hidden;
        &.open{
          height: 250px;
        }
      }
    }
  }
  .wrap-chips{
    display: flex;
    gap: 30px;
    .wrappy-chips{
      display: flex;
    }
  }
  .wrap-line-action{
    display: flex;
    justify-content: space-between;
    margin: 15px 0 5px 0;
  }
  .wrap-action{
    padding: 0.25rem 2rem;
    gap: 10px;
    display: flex;
    justify-content: end;
  }
`

export default CreneauxEnCours;