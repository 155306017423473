import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {TabChipsStatut} from "../CreneauxEnCours/CreneauxEnCours";
import TimedRoundAvatar from "../../../../components/atoms/TimedRoundAvatar/TimedRoundAvatar";
import {ConvertBase5} from "../../../../components/functions/TimesFunctions";
import {BsCheck, BsTrash} from "react-icons/bs";
import TimedIconButton from "../../../../components/atoms/TimedIconButton/TimedIconButton";
import {AiOutlineDownload} from "react-icons/ai";
import {BiChevronDown, BiUnlink} from "react-icons/bi";
import FormEditCreneau from "./FormEditCreneau";
import {HiOutlineDuplicate} from "react-icons/hi";
import {useFreeCreneau, useMajCreneau, useTrashCreneau} from "../../../../features/creneau/creneau.hooks";
import {useModal} from "../../../../hooks/useModal";
import {useTrashPlage} from "../../../../features/plage/plage.hooks";
import ModalConfirmation from "../../../Modaux/ModalConfirmation";
// import {getIsHorseV2} from "../../../../components/functions/PlageFunctions";
import ModalWarning from "../../../Modaux/ModalWarning";
import ModalValideCreneaux from "../../../Modaux/ModalValideCreneaux";

interface CardCreneauCtrlProps{
    className?:string;
    Creneau:Creneau;
    setCrenDup:Function;
    supervisor?:boolean;
}

const CardCreneauCtrl = ({className, Creneau, setCrenDup, supervisor}:CardCreneauCtrlProps)=>{
    const mutationTrash = useTrashCreneau();
    // const mutationValideCreneau = useValideCreneau();
    // const [go, setGo] = useState(false)
    //const PlageQuery = useGetPlageDateWait(new Date(Creneau.creneauAt.slice(0,10)).toISOString().slice(0,10), Creneau.UserAffected ? Creneau.UserAffected.id : undefined, go);
    const mutationFree = useFreeCreneau();
    const mutationMajCren = useMajCreneau();
    const [statut, setStatut] = useState<number>(0)
    const [classColor, setClassColor] = useState<string>("Primary");
    const [segmentation, setSegmentation] = useState<string>('Matin');
    const [open, setOpen] = useState<boolean>(false);
    const [busy, setBusy] = useState<boolean>(false);
    const {open:openTrash, toggle} = useModal();
    const {open:openWarning, toggle:toggleWarning} = useModal();
    const {open:openValidation, toggle:toggleValidation} = useModal();
    const mutationT = useTrashPlage();
    const handleLibereCreneauValide = ()=>{
        toggle();
    }
    const CancelTrash = ()=>{
        toggle();
    }
    const TrashOnePlage = ()=>{
        if(Creneau.Plage) {
            setBusy(true);
            mutationT.mutate((Creneau.Plage.id), {
                onSuccess: () => {
                    MajUpdateCreneau(Creneau);
                },
                onError: () => {
                    toggle();
                    setBusy(false);
                }
            })
        }
    }
    const MajUpdateCreneau = (creneau:Creneau)=>{
        const datas:CreneauFD = {
            id:creneau.id,
            updateAt:new Date().toLocaleString("en-US", {timeZone: "Europe/Paris"})
        }
        mutationMajCren.mutate((datas), {
            onSuccess: () => {
                toggle();
                setBusy(false);
            },
            onError: () => {
                toggle();
                setBusy(false);
            }
        })
    }
    useEffect(()=>{
        const SegCren = Creneau.heureFin - Creneau.heureDebut > 60 ? 'Journée' : Creneau.heureDebut < 144 ? 'Matin' : 'Après-midi'
        setSegmentation(SegCren);
        setStatut(!!Creneau.treatAt ? 2 : !!Creneau.UserAffected ? 1 : 0)
        const StatutChips = TabChipsStatut.find(c=>c.id === statut);
        if(StatutChips){
            setClassColor(StatutChips.themeColor);
        }
    }, [Creneau, statut])
    const handleDownLoad = ()=>{
        if(Creneau.Contrat){
            window.open(`${process.env.REACT_APP_API_URL_ROOT}${Creneau.Contrat.documentUrl}`);
        }
    }
    const handleFree = ()=>{
        setBusy(true)
        mutationFree.mutate((Creneau.id), {
            onSuccess:()=>{
                setBusy(false);
            }, onError:()=>{
                setBusy(false);
            }
        })
    }
    const handleValideCreneau = ()=>{
        toggleValidation();
    }
    const handleDuplicate = ()=>{
        setCrenDup(Creneau);
    }
    const handleSuppr = ()=>{
        setBusy(true)
        mutationTrash.mutate((Creneau.id),{
            onSuccess:()=>{
                //toggle();
                setBusy(false);
            }, onError:()=>{
                //toggle();
                setBusy(false);
            }
        })
    }
    return (
        <div className={`CardCreneau ${className} ${classColor}`}>
            <div className={"line-infos"}>
                <div className={`switcher`}>
                    {!Creneau.treatAt &&
                        <div className={`wra-switcher ${open ? 'isOpened' : 'isClosed'}`} onClick={()=>setOpen(_o=>!_o)}>
                            <BiChevronDown/>
                        </div>
                    }
                </div>
                <div className={'user-replaced'}>
                    <div className={`avatar-place`}>
                        <TimedRoundAvatar BoxShadow={"1px 1px 3px rgba(0,0,0,.7)"} Border={true} src={Creneau.UserReplaced.avatarUrl!==undefined ? Creneau.UserReplaced.avatarUrl : '' } size={"other"} sizeP={"26px"}/>
                    </div>
                    <div className="name">{Creneau.UserReplaced.fullName}</div>
                </div>
                <div className="lieu">
                    <div className={`in-lieu`}>
                       {Creneau.Lieu.libelle}
                    </div>
                </div>
                <div className="creneauAt isWeight">{new Date(Creneau.creneauAt).toLocaleString().slice(0,10)}</div>
                <div className="segmentation">{segmentation}</div>
                <div className="heureDebut isWeight">{ConvertBase5(Creneau.heureDebut)}</div>
                <div className="heureFin isWeight">{ConvertBase5(Creneau.heureFin)}</div>
                {Creneau.UserAffected ?
                    <div className="user-affected">
                        <div className={`avatar-place`}>
                            <TimedRoundAvatar BoxShadow={"1px 1px 3px rgba(0,0,0,.7)"} Border={true} src={Creneau.UserAffected.avatarUrl!==undefined ? Creneau.UserAffected.avatarUrl : '' } size={"other"} sizeP={"26px"}/>
                        </div>
                        <div className="name">{Creneau.UserAffected.fullName}</div>
                    </div>:
                    <div className="no-affected">--créneaux à prendre--</div>
                }
                <div className="niveau">Niv {Creneau.niveau}</div>
                <div className="aid">{Creneau.aide ? 'Avec' : 'Sans'} aide</div>
                <div className={`actions-creneaux`}>
                    {statut === 2 ?
                        <div className={"group-btns"}>
                            <TimedIconButton
                                size={"sm"}
                                themeColor={"Warning"}
                                Icon={<BsTrash/>}
                                toolTip={"supprimer"}
                                onClick={handleLibereCreneauValide}
                                BackAlw={true}
                                isActive={false}
                                isPending={busy}
                            />
                            <TimedIconButton
                                size={"sm"}
                                themeColor={"Primary"}
                                Icon={<AiOutlineDownload/>}
                                toolTip={"Télécharger contrat"}
                                onClick={handleDownLoad}
                                BackAlw={true}
                                isActive={false}
                                isPending={busy}
                            />
                        </div>:
                        statut === 1 ?
                            <div className={"group-btns"}>
                                <TimedIconButton
                                    size={"sm"}
                                    themeColor={"Primary"}
                                    Icon={<BiUnlink/>}
                                    toolTip={"Libérer le créneau"}
                                    onClick={handleFree}
                                    BackAlw={true}
                                    isActive={false}
                                    isPending={busy}
                                />
                                <TimedIconButton
                                    size={"sm"}
                                    themeColor={"Success"}
                                    Icon={<BsCheck/>}
                                    toolTip={"valider"}
                                    onClick={handleValideCreneau}
                                    BackAlw={true}
                                    isActive={false}
                                    isPending={busy}
                                />
                            </div>:
                            <div className={"group-btns"}>
                                <TimedIconButton
                                    size={"sm"}
                                    themeColor={"Primary"}
                                    Icon={<HiOutlineDuplicate/>}
                                    toolTip={"Dupliquer"}
                                    onClick={handleDuplicate}
                                    BackAlw={true}
                                    isActive={false}
                                />
                                <TimedIconButton
                                    size={"sm"}
                                    themeColor={"Warning"}
                                    Icon={<BsTrash/>}
                                    toolTip={"supprimer"}
                                    onClick={handleSuppr}
                                    BackAlw={true}
                                    isActive={false}
                                    isPending={busy}
                                />
                            </div>
                    }
                </div>
            </div>
            {!Creneau.treatAt && open &&
                <div className={`wrap-form-edit ${open ? 'isOpened' : 'isClosed'}`}>
                    <FormEditCreneau Creneau={Creneau} supervisor={supervisor}/>
                </div>
            }
            {openTrash &&
                <ModalConfirmation
                    text={"Etes-vous sûr de vouloir annuler ce remplacement ?"}
                    callBackConfirm={TrashOnePlage}
                    Close={CancelTrash}
                    isPending={busy}
                />
            }
            {openWarning &&
                <ModalWarning
                    text={<div><p className={"already_take"}>Le créneau ne peut être validé car le docteur <strong>{Creneau.UserAffected?.nom}</strong> est déjà affecté.</p><p>Veuillez libérer le planning du docteur <strong>{Creneau.UserAffected?.nom}</strong>, ou libérer ce créneau pour qu'il puisse être pris par quelqu'un d'autre</p></div> }
                    Close={toggleWarning}
                    isPending={busy}
                />
            }
            {openValidation &&
                <ModalValideCreneaux
                    Close={toggleValidation}
                    Creneau={Creneau}
                />
            }
        </div>
    )
}

const CardCreneau = styled(CardCreneauCtrl)`
  .creneauAt{
    width: 75px;
    
  }
  .group-btns{
    display: flex;
    gap: 10px;
  }
  .wrap-form-edit{
    transition: height 0.3s;
    overflow: hidden;
    &.isClosed{
      height: 0;
     
    }
    &.isOpened{
      height: 200px;
    }
  }
  .switcher{
    width: 20px;
    height: 30px;
    .wra-switcher{
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      flex-direction: column;
      transform: rotate(0deg);
      transition: transform 0.3s;
      &:hover{
        cursor: pointer;
      }
      &.isOpened{
        transform: rotate(180deg);
      }
    }
    svg{
      font-size: 20px;
    }
  }
  .niveau{
    width: 46px;
    text-align: center;
  }
  .aid{
    width: 61px;
  }
  .actions-creneaux{
    flex-grow: 1;
    display: flex;
    justify-content: end;
  }
  .segmentation{
    width: 70px;
    text-align: center;
  }
  .lieu{
    width: 160px;
    .in-lieu{
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 160px;
      display: block;
      overflow: hidden
    }
  }
  .heureDebut, .heureFin{
    width: 50px;
    text-align: center;
  }
  .no-affected{
    width: 190px;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
  }
  .user-replaced, .user-affected{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 5px;
    width: 190px;
    
    
    .avatar-place{
      width: 30px;
    }
    .name{
      width: calc(100% - 30px);
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
    }
    .name{
      font-weight: bold;
    }
  }
  .isWeight{
    font-weight: bold;
  }
  font-size: 14px;
  width: 100%;
  margin-bottom: 10px;
  background: white;
  border-radius: 8px;
  border-left-style: solid;
  border-left-width: 20px;
  border-left-color: ${props=>props.theme.PrimaryExtraLight};
  padding: 0.5rem;
  .line-infos{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;  
  }
  &.Complementary{
    border-left-color: ${props=>props.theme.ComplementaryExtraLight};
  }
  &.Tertiary{
    border-left-color: ${props=>props.theme.TertiaryExtraLight};
  }
  &.Secondary{
    border-left-color: ${props=>props.theme.SecondaryExtraLight};
  }
`

export default CardCreneau;